var $ = jQuery.noConflict();

$(document).ready(function($) {
    "use strict";

    $(".dropdown").hover(function() {
        //window.mytimeout = setTimeout(function() {
            $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true,false).slideDown("400");
            $(this).toggleClass('open');
        //}, 2000);
    },
    function() {
        $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true,false).slideUp("400");
        $(this).toggleClass('open');
        //clearTimeout(window.mytimeout);
    });


    $('.navigation').hover(function() {
            $('.body-overlay').css('display', 'block').stop();
        },
        function() {
            $('.body-overlay').css('display', 'none').stop();
        }
    );


    function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " HUF"
    }

        var productimage;
        var productprice;
        var productcategory;

        $('#searchbox').selectize({
            valueField: 'url',
            labelField: 'title',
            searchField: ['title', 'sku'],
            maxOptions: 10,
            options: [],
            create: false,
            render: {
                option: function (item, escape) {
                    if (item.image_id > 0) {
                        productimage = 'https://onlinegep.hu/' + item.image.thumbnail_path;
                    } else {
                        productimage = 'https://onlinegep.hu/assets/images/null.jpg';
                    }
                    if (item.class === 'product') {
                        if (item.discount_price !== false) {
                            productprice =
                                    '<div class="media-right media-middle"><div class="productprice">' +
                                    '<span class="original-price">' + formatNumber(item.price) + '</span>' +
                                    '<br><span class="discount-price">' + formatNumber(item.discount_price) + '</span>' + '</div></div></div>';;
                        } else {
                            productprice = '<div class="media-right media-middle"><div class="productprice">' + formatNumber(item.price) + '</div></div></div>';
                        }
                    } else {
                        productprice = '';
                    }
                    if (item.class === 'product') {
                        productcategory = item.category_list;
                    } else {
                        productcategory = item.category_list;
                    }
                    //return '<div class="media"><div class="media-left media-middle"><img class="media-object" src="' + productimage + '"></div><div class="media-body"><span class="name">' + escape(item.title) + '</span><br><span class="productcategory">' + productcategory + '</span></div><div class="media-right media-middle"><div class="productprice">' + productprice + '</div></div></div>';
                    return '<div class="media"><div class="media-left media-middle"><img class="media-object" src="' + productimage + '"></div><div class="media-body media-middle"><span class="name">' + escape(item.title) + '</span><br><span class="productcategory">' + productcategory + '</span></div>' + productprice;
                }
            },
            optgroups: [
                {value: 'product', label: 'Termékek'},
                {value: 'category', label: 'Kategóriák'}
            ],
            optgroupField: 'class',
            optgroupOrder: ['product', 'category'],
            load: function (query, callback) {
                if (!query.length) return callback();
                //if(query.length > 2) {
                $.ajax({
                    url: "https://onlinegep.hu/api/search",
                    type: 'GET',
                    dataType: 'json',
                    //contentType: "application/json; charset=utf-8",
                    data: {
                        q: query
                    },
                    error: function () {
                        callback();
                    },
                    success: function (res) {
                        callback(res.data);
                        //console.log(res.data);
                    }
                });
                //} else callback();
            },
            score: function scoreFilter(search) {
                var ignore = search && search.length < 3;
                var score = this.getScoreFunction(search);
                //the "search" argument is a Search object (see https://github.com/brianreavis/selectize.js/blob/master/docs/usage.md#search).
                return function onScore(item) {
                    if (ignore) {
                        //If 0, the option is declared not a match.
                        return 0;
                    } else {
                        var result = score(item);
                        return result;
                    }
                };
            },
            onChange: function () {
                window.location = this.items[0];
            }
        });




    var tpj=jQuery;

            var revapi9;
            tpj(document).ready(function() {
                if(tpj("#rev_slider_9_1").revolution == undefined){
                    revslider_showDoubleJqueryError("#rev_slider_9_1");
                }else{
                    revapi9 = tpj("#rev_slider_9_1").show().revolution({
                        sliderType:"carousel",
jsFileLocation:"//darknetworks.hu/p/vendor/visual-editor/revslider/public/assets/js/",
                        sliderLayout:"fullwidth",
                        dottedOverlay:"none",
                        delay:9000,
                        navigation: {
                            keyboardNavigation:"off",
                            keyboard_direction: "horizontal",
                            mouseScrollNavigation:"off",
                             mouseScrollReverse:"default",
                            onHoverStop:"off",
                            arrows: {
                                style:"uranus",
                                enable:true,
                                hide_onmobile:true,
                                hide_under:600,
                                hide_onleave:true,
                                hide_delay:200,
                                hide_delay_mobile:1200,
                                tmp:'',
                                left: {
                                    h_align:"left",
                                    v_align:"center",
                                    h_offset:30,
                                    v_offset:0
                                },
                                right: {
                                    h_align:"right",
                                    v_align:"center",
                                    h_offset:30,
                                    v_offset:0
                                }
                            }
                            ,
                            thumbnails: {
                                style:"custom",
                                enable:true,
                                width:75,
                                height:75,
                                min_width:75,
                                wrapper_padding:0,
                                wrapper_color:"transparent",
                                wrapper_opacity:"1",
                                tmp:'<span class="tp-thumb-image"></span>',
                                visibleAmount:5,
                                hide_onmobile:true,
                                hide_under:800,
                                hide_onleave:false,
                                direction:"horizontal",
                                span:false,
                                position:"inner",
                                space:5,
                                h_align:"center",
                                v_align:"bottom",
                                h_offset:-368,
                                v_offset:20
                            }
                        },
                        carousel: {
                            horizontal_align: "center",
                            vertical_align: "center",
                            fadeout: "off",
                            maxVisibleItems: 3,
                            infinity: "on",
                            space: 0,
                            stretch: "off"
                        },
                        viewPort: {
                            enable:true,
                            outof:"pause",
                            visible_area:"80%",
                            presize:false
                        },
                        responsiveLevels:[1140,1024,778,480],
                        visibilityLevels:[1140,1024,778,480],
                        gridwidth:[1140,1024,778,480],
                        gridheight:[450,450,500,400], // 600 600 500 400
                        lazyType:"none",
                        parallax: {
                            type:"mouse",
                            origo:"slidercenter",
                            speed:2000,
                            levels:[2,3,4,5,6,7,12,16,10,50,47,48,49,50,51,55],
                            type:"mouse",
                        },
                        shadow:0,
                        spinner:"off",
                        stopLoop:"off",
                        stopAfterLoops:-1,
                        stopAtSlide:-1,
                        shuffle:"off",
                        autoHeight:"off",
                        hideThumbsOnMobile:"on",
                        hideSliderAtLimit:0,
                        hideCaptionAtLimit:0,
                        hideAllCaptionAtLilmit:0,
                        debugMode:false,
                        fallbacks: {
                            simplifyAll:"off",
                            nextSlideOnWindowFocus:"off",
                            disableFocusListener:false,
                        }
                    });
                }
            }); /*ready*/


});
